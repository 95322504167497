<template>
    <section class="obras-vigentes-cards">
        <titulo-divisor titulo="Lista de obras en ejecución">
                <div class="row">
                    <div class="col auto">
                        <input-search
                        v-model="query"
                        placeholder="Buscar obra"
                        :search="Action_get_active_obras"
                        auto-search
                        />
                    </div>
                </div>
        </titulo-divisor>
        <div class="row mt-4 listado-obras-cards overflow-auto custom-scroll">
            <div v-for="data in obras" :key="data.id" class="col-12 col-md-6 col-lg-5 col-xl-auto mb-3" @click="verObra(data)">
                <card-obra-vigente
                :name="data.cliente"
                :nameProject="data.nombre"
                :type="data.type"
                :date="formatDate(data.fecha_inicio, data.fecha_fin)"
                :cantidad="data.numero_equipos"
                :percentage="data.porcentaje"
                :totalValue="data.valor"
                />
            </div>
        </div>
        <div class="row mt-2 justify-content-center">
            <div class="col-auto">
                <Pagination :pagination="pagination" @paginate="Action_get_active_obras"/>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            obras: 'obras/vigentes/obras',
            pagination: 'obras/vigentes/pagination',
        }),
        query: {
            get(){
                return this.$store.getters['obras/vigentes/query']
            }, 
            set(val){
                this.$store.commit('obras/vigentes/set_query', val)
            }
        },
    },
    created(){
        this.Action_get_active_obras()
    },
    methods:{
        ...mapActions({
            Action_get_active_obras: 'obras/vigentes/Action_get_active_obras',
        }),
        verObra(data){
            this.$router.push({ name: 'obras.vigentes.ver' , params: { id: data.id}})
        },
        formatDate(inicio, fin){
            const dateFilter = this.$options.filters['helper-fecha']
            return `${dateFilter(inicio, 'DD MMM. YYYY')} - ${dateFilter(fin, 'DD MMM. YYYY')}`
        }
    }
}
</script>

<style lang="scss" scoped>
.obras-vigentes-cards{
    .listado-obras-cards{
        height: calc(100vh - 340px);
    }
}
</style>